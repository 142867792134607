import { OrdersMinor, ProfileMinor, AnalyticsMajor } from "@shopify/polaris-icons";
import { Navigation } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Nav = () => {
  const user = useSelector((state) => state.auth.user) || {};

  const location = useLocation();

  if (user.type === "admin") {
    return (
      <Navigation location={location.pathname}>
        <Navigation.Section
          items={[
            {
              url: "/cards",
              label: "Cards",
              icon: OrdersMinor,
            },
            {
              url: "/profiles",
              label: "Users",
              icon: ProfileMinor,
            },
            {
              url: "/stats",
              label: "Stats",
              icon: AnalyticsMajor,
            },
          ]}
        />
      </Navigation>
    );
  }
  return null;
};
export default Nav;
