import { login, logout } from "./authReducer";

const baseURL = process.env.NODE_ENV !== "production" ? process.env.REACT_APP_API_URL : "";

export const checkAuth = (navigate, location) => async (dispatch) => {
  try {
    if (location.pathname === "/register" || location.pathname === "/login") return;

    const res = await fetch(`${baseURL}/api/users`, {
      credentials: "include",
    });

    const data = await res.json();
    console.log(data);

    if (data.error || !data.active) {
      dispatch(logout());
      navigate("/login");
    } else {
      // Update the redux state with the user information
      dispatch(login(data));
    }
  } catch (error) {
    console.log("NO TOKEN WAS FOUND");
    // Handle any error that occurs during the authentication process
    console.error(error);
    // Navigate to the login page if there is an error
    navigate("/login");
  }
};
