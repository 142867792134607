import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { Frame, Loading } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "./redux/auth.js";

import Dashboard from "./pages/Dashboard.jsx";
import TopNavBar from "./components/TopBar.jsx";
import Nav from "./components/Nav.jsx";
import ScrollToTopOnNavigate from "./components/ScrollToTopOnNavigate.jsx";

const Cards = lazy(() => import("./pages/Cards.jsx"));
const Login = lazy(() => import("./pages/Login.jsx"));
const Register = lazy(() => import("./pages/Register.jsx"));
const Profile = lazy(() => import("./pages/Profile.jsx"));
const Profiles = lazy(() => import("./pages/Profiles.jsx"));
const Stats = lazy(() => import("./pages/Stats.jsx"));
const DesignerStats = lazy(() => import("./pages/DesignerStats.jsx"));
const CardDetails = lazy(() => import("./pages/CardDetails.jsx"));

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.auth.user) || {};

  useEffect(() => {
    dispatch(checkAuth(navigate, location));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, navigate]);

  const logo = {
    width: 90,
    topBarSource:
      "https://cdn.shopify.com/s/files/1/0514/8740/8304/files/Just_Lyne_Black_Logo_ed4d72e9-cc49-42f5-8101-b0ce8c8c9051_160x.png",
    url: "/cards",
    accessibilityLabel: "Just Lyne",
  };

  return (
    <Frame topBar={<TopNavBar />} navigation={user?.type === "admin" && <Nav />} logo={logo}>
      <Suspense fallback={<Loading />}>
        <ScrollToTopOnNavigate />
        <Routes>
          {/* <Route path="/" element={<Home />}></Route> */}
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/" element={<Dashboard />}>
            <Route index redirectTo="/cards" />
            <Route path="/cards" element={<Cards />} />
            <Route path="/cards/:id" element={<CardDetails />} />

            <Route path="/profiles" element={<Profiles />} />
            <Route path="/profiles/:id" element={<Profile />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="/stats" element={<Stats />} />
            <Route path="/stats/:id" element={<DesignerStats />} />
          </Route>
        </Routes>
      </Suspense>
    </Frame>
  );
}

export default App;
