import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./redux/authReducer.js";

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
  // other configuration options can be specified here
});

export default store;
