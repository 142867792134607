import { TopBar, ActionList, Icon, Text } from "@shopify/polaris";
import { ArrowLeftMinor, ArrowRightMinor, ProfileMinor, AnalyticsMajor } from "@shopify/polaris-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDateAsString } from "../helper/date.js";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/authReducer.js";

const baseURL = process.env.NODE_ENV !== "production" ? process.env.REACT_APP_API_URL : "";

function TopNavBar() {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  const handleLogout = async () => {
    try {
      await fetch(`${baseURL}/api/auth/logout`, {
        method: "POST",
        credentials: "include",
      });

      navigate("/login");
      dispatch(logout());
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchResultsDismiss = () => {
    setIsSearchActive(false);
    setSearchValue("");
    setSearchResults([]);
  };

  const handleSearchChange = async (value) => {
    setSearchValue(value);
    setIsSearchActive(value.length > 0);

    const queryString = new URLSearchParams({ search: value }).toString();
    try {
      setLoading(true);
      const res = await fetch(`${baseURL}/api/cards?${queryString}&limit=3`, {
        credentials: "include",
      });
      const data = await res.json();
      console.log(data);
      let results = [];
      data.cards.map((c) => {
        return results.push({
          content: (
            <Text as="p" fontWeight="medium">
              Card {c.name}
            </Text>
          ),
          helpText: (
            <>
              <small>
                Placed by {c.customerName} on {getDateAsString(c.createdAt)}.
              </small>
              <div>
                <small>
                  List: <strong>{c.list}</strong> Status: <strong>{c.status}</strong>
                </small>
              </div>
            </>
          ),
          onAction: () => {
            navigate(`cards/${c.lineItemId}`);
            setSearchValue("");
            setSearchResults([]);
          },
        });
      });
      setSearchResults(results);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigationToggle = () => {
    console.log("toggle navigation visibility");
  };

  const userMenuMarkup = isAuthenticated ? (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            { content: "Profile", icon: ProfileMinor, onAction: () => navigate("/profile") },
            ...(user?.type === "designer"
              ? [
                  {
                    content: "Stats",
                    icon: AnalyticsMajor,
                    onAction: () => navigate(`/stats/${user?._id}`),
                  },
                ]
              : []),
            {
              content: "Log out",
              icon: ArrowLeftMinor,
              onAction: handleLogout,
            },
          ],
        },
      ]}
      name={`${user.firstName} ${user.lastName}`}
      detail={`${user.type[0].toUpperCase()}${user.type.slice(1)}`}
      initials={`${user.firstName[0]}${user.lastName[0]}`}
      avatar={`https://avatar.oxro.io/avatar.svg?fontSize=180&name=${user.firstName} ${user.lastName[0]}`}
      open={isUserMenuOpen}
      onToggle={() => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen)}
    />
  ) : (
    <TopBar.Menu
      activatorContent={
        <>
          <Icon source={ArrowRightMinor} color="subdued" />
          <span style={{ marginLeft: "5px" }}> Login</span>
        </>
      }
      open={false}
      onOpen={() => navigate("/login")}
    />
  );
  const searchResultsMarkup = <ActionList items={searchResults} />;

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchChange}
      value={searchValue}
      placeholder="Search"
      showFocusBorder
      loading={loading}
    />
  );

  return (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchResultsVisible={isSearchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={handleNavigationToggle}
    />
  );
}

export default TopNavBar;
